// Photo.js
import React from 'react';

function ClientItem({ image }) {
  return (
    <div className="client">
      <img src={image} alt="client" />
    </div>
  );
}

export default ClientItem;
