import React from 'react';

const PersonalInfo = ({ formData, onChange }) => {
  return (
    <div className='form-data'>
      <label htmlFor="name">Nom:</label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        required
      />
   
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        required
      />
    </div>
  );
};

export default PersonalInfo;
