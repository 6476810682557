import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from "../images/logo.png";
import MenuIcon from '@mui/icons-material/Menu';
import '../styles/Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src={Logo} alt="Logo" />
        </Link>
        <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
          <NavLink exact to="/" className="navbar-link" activeClassName="active">Accueil</NavLink>
          <NavLink to="/services" className="navbar-link" activeClassName="active">Services</NavLink>
          <NavLink to="/clients" className="navbar-link" activeClassName="active">Client</NavLink>
          <NavLink to="/contact" className="navbar-link" activeClassName="active">Contact</NavLink>
        </div>
        <div className="navbar-toggle" onClick={toggleNavbar}>
          <MenuIcon />
        </div>
      </div>
    </nav>
  );
};

export default Header;
