import React from "react";
import { serviceList } from "../data/Data.js";
import ServiceItem from "../components/ServiceItem.js";
import Layout from "../components/Layout.js";
import "../styles/Services.css";

function Services() {
  return (
    <Layout>
        <div className="services">
        <h1 className="serviceTitle">Nos Services</h1>
        <div className="serviceList">
            {serviceList.map((serviceItem, key) => {
            return (
                <ServiceItem
                key={key}
                image={serviceItem.image}
                titre={serviceItem.titre}
                description={serviceItem.description}
                />
            );
            })}
        </div>
        </div>
    </Layout>
  );
}

export default Services;
