import React from 'react';

const MessageInput = ({ formData, onChange }) => {
  return (
    <div className='form-message'>
      <label htmlFor="message">Message:</label>
      <textarea
        id="message"
        name="message"
        value={formData.message}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        required
      />
    </div>
  );
};

export default MessageInput;
