import React, { useState } from 'react';
import Layout from '../components/Layout.js';
import PersonalInfo from '../components/PersonalInfo.js';
import AdditionalInfo from '../components/AdditionalInfo.js'; // Ajout du nouveau composant
import MessageInput from '../components/MessageInput.js';
import '../styles/Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    company: 'Astecmo', // Nouveau champ
    address: '18  , Rue Florence Arthaud<br />91300 Massy - FRANCE', // Nouveau champ
    mail: 'astecmo@outlook.fr',
    phone: '+33 1 60 11 15 93' // Nouveau champ
  });

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // Vous pouvez ici envoyer les données à un serveur ou faire autre chose avec elles
  };

  return (
    <Layout>
      <div className="contact-container">
        <h1>Contactez-nous</h1>
        <div className='contact'>
            <AdditionalInfo formData={formData} onChange={handleChange} /> {/* Placé en dehors du formulaire */}
            <form onSubmit={handleSubmit} className="contact-form">
            <PersonalInfo formData={formData} onChange={handleChange} />
            <MessageInput formData={formData} onChange={handleChange} />
            <button type="submit">Envoyer</button>
            </form>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
