import React from "react";
import { clientList } from "../data/Data.js";
import ClientItem from "../components/ClientItem.js";
import Layout from "../components/Layout.js";
import "../styles/Client.css";

function Clients() {
  return (
    <Layout>
        <div className="clients">
        <h1 className="clientsTitle">Nos Clients</h1>
        <div className="clientsList">
            {clientList.map((clientItem, key) => {
            return (
                <ClientItem
                key={key}
                image={clientItem.image}
                />
            );
            })}
        </div>
        </div>
    </Layout>
  );
}

export default Clients;
