import React from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Acceuil from "../images/Acceuil.png";
import Acceuil_1 from "../images/Acceuil_1.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "../styles/Home.css";

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  return (
    <Layout>
        <Slider {...settings}>
        <div className="slider-item">
              <img src={Acceuil_1} alt="Acceuil_1" />
              <div className="slider-content-1">
                    <h1>ASTECMO</h1>               
                    <h2>Un partenaire sur lequel vous pouvez compter</h2>
                    <p>Forte de son expérience et savoir-faire dans le domaine de la 
                        maintenance électromécanique, pneumatique et hydraulique. 
                        ASTECMO accompagne ses clients, de toutes tailles, aussi grâce à ses 
                        services associés tels que l’installation de machines et équipements 
                        industriels, le montage mécanique, et allant jusqu’au déménagement de 
                        sites industriels complets.
                    </p>
              </div>
          </div>
          <div className="slider-item">
              <img src={Acceuil} alt="Accueil " />
              <div className="slider-content-2">
                  <h2>Votre partenaire de confiance</h2>
                  <p>N’hésitez pas à nous contacter pour tout renseignement ou devis. Nous serons ravis de vous répondre dans les meilleurs délais.</p>
                  <Link to="/contact">
                      <button>Contact</button>
                  </Link>
              </div>
          </div>

        </Slider>
    </Layout>
  );
};

const CustomPrevArrow = (props) => (
  <div className="custom-prev-arrow" onClick={props.onClick}>
    <ArrowBackIcon className="icon-arrow"/>
  </div>
);

const CustomNextArrow = (props) => (
  <div className="custom-next-arrow" onClick={props.onClick}>
    <ArrowForwardIcon className="icon-arrow"/>
  </div>
);

export default Home;
