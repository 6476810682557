import React from "react";

function ServiceItem({ image, titre, description }) {
  return (
    <div className="serviceItem">
      <div style={{ backgroundImage: `url(${image})` }}> </div>
      <h1> {titre} </h1>
      <p> {description} </p>
    </div>
  );
}

export default ServiceItem;
