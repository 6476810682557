import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Pagenotfound from "./pages/Pagenotfound";
import Clients from "./pages/Clients"
import Contact from "./pages/Contact";
import "./App.css";

function App() {
  return (
    
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Pagenotfound/>} />
        </Routes>
      </BrowserRouter>
    </div>
    
  );
}

export default App;

