import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const AdditionalInfo = ({ formData }) => {
  return (
    <div className='additional-info'>
        <div className='info'>
            <div className='info-icon'>
                <BusinessIcon />
            </div>
            <div className='info-data'>
                <span>{formData.company}</span>
            </div>
        </div>
        <div className='info'>
            <div className='info-icon'>
                <LocationOnIcon className='location-icon'/> 
            </div>
            <div className='info-data'>
                <span dangerouslySetInnerHTML={{ __html: formData.address }} /> {/* Utilisation de dangerouslySetInnerHTML */}
            </div>
        </div>
        <div className='info'>
            <div className='info-icon'>
                <EmailIcon /> 
            </div>
            <div className='info-data'>
                <span>{formData.mail}</span>
            </div>
        </div>
        <div className='info'>
            <div className='info-icon'>
                <PhoneIcon />
            </div>
            <div className='info-data'>
                <span>{formData.phone}</span>
            </div> 
        </div>
    </div>
  );
};

export default AdditionalInfo;