import Maintenance_industrielle from "../images/Maintenance_industrielle.png";
import Installation_machines from "../images/Installation_machines.png";
import Demenagement_industriel from "../images/Demenagement_industriel.png";
import Automatisation from "../images/Automatisation.png";
import Negoce_ventes from "../images/Negoce_ventes.png";
import Modula from "../images/modula.png";
import Safran from "../images/Safran.jpg"
import Toyota from "../images/toyota.png";
import Trellborg from "../images/trelleborg.png";
import Wilaalson from "../images/wilaalson.png";
import Hutchinson from "../images/hutchinson.png";


export const serviceList  = [
    {
        titre: "Maintenance Industrielle", 
        description: 'Afin d’optimiser le fonctionnement de vos lignes de production, notre équipe de techniciens qualifiés est disponible 7j/7 pour intervenir dans le cadre d’un contrat de maintenance ainsi que pour une assistance technique ou remise en conformité.',
        image: Maintenance_industrielle,
    },
    {
        titre: "Installation Machines et Montage Mécanique", 
        description: "Ayant la confiance de leaders dans leurs domaines pour installer des machines et équipements industriels, nous nous engageons à vous apporter une prestation de qualité grâce à nos techniciens qualifiés et expérimentés.",
        image: Installation_machines,
    },
    {
        titre: "Déménagement Industriel", 
        description: "Nous apportons des solutions clé-en-main, du transfert d’un simple équipement à toute une ligne de production. Nous nous occupons de tout le processus du démontage jusqu’au remontage et remise en service de la production. Faites-nous confiance aussi pour le démantèlement de vos installations en fin de vie. Nous intervenons en conformité avec la réglementation et les normes environnementales. ",
        image: Demenagement_industriel,
    },
    {
        titre: "Automatisation et Travaux Neufs", 
        description: "Augmenter : Productivité, Rentabilité, et Sécurité, tout en réduisant les couts de production. ASTECMO vous accompagne - grâce à ses techniciens et ingénieurs, aussi doués que précis - dans vos projets d’Automatisation de votre ligne de production et tous vos travaux neufs.",
        image: Automatisation,
    },
    {
        titre: "Négoce Et Vente De Matériel", 
        description: "Grace à son réseau, ASTECMO se propose d’étudier les prix de vos fournisseurs et de trouver la solution la plus efficiente afin de diminuer vos couts et augmenter la qualité des matériels ou pièces de rechange.",
        image: Negoce_ventes,
    },
];

export const clientList =[
    {
        id: 1,
        image: Modula,

    },
    {
        id: 2,
        image: Safran,

    },
    {
        id: 3,
        image: Trellborg,

    },
    {
        id: 4,
        image: Toyota,

    },
    {
        id: 5,
        image: Hutchinson,

    },
    {
        id: 6,
        image: Wilaalson,

    },
    
];
